<template>
  <v-col cols="12">
    <v-card>
      <v-card-title class="font-weight-regular">
        <v-container class="px-0 py-0">
          <v-row align="center">
            <v-col cols="auto" class="text-reference">
              {{ $t('reference') }}:
            </v-col>
            <v-col cols="3" class="flex-grow-1">
              <v-select
                :items="getCompanyBusinessYears"
                item-value="term"
                item-text="term"
                v-model="selectedTerm"
                return-object
                @change="getDataFromApi"
                solo
                flat
                dense
                class="term-select"
                background-color="term-select"
                color="primary"
                hide-details
                append-icon="$customChevronDown"
              >
                <template v-slot:item="{ item }">
                    {{
                      $t('year_label', {
                        number: item.term,
                        start: item.start_date.slice(0, -3),
                        end: item.end_date.slice(0, -3)
                      })
                    }}
                </template>
                <template v-slot:selection="{ item }">
                    {{
                      $t('year_label', {
                        number: item.term,
                        start: item.start_date.slice(0, -3),
                        end: item.end_date.slice(0, -3)
                      })
                    }}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="auto" class="year-sales year-sales-label">
            Year Sales
          </v-col>
          <v-col cols="4" class="year-sales year-sales-value">
            {{ getCompanySalesGoalsBusinessYear.total_sale | toCurrency }}
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="px-0">
        <Table
          class="custom-table"
          :headers="parseHeaders()"
          :items="parseItems()"
          :number-of-pages="1"
          :total-records="items.length"
          :footer="false"
          :loading="loading"
        >
          <template v-slot:item="{ item, index, headers }">
            <tr>
              <td
                class="text-center no-border"
                v-for="header in headers"
                :key="`header-${index}-${header.value}-${header.text}`"
              >
                <template v-if="header.value !== 'name'">
                  <template
                    v-if="
                      item.type === 'amount_goal' || item.type === 'amount_sale'
                    "
                  >
                    <span :class="item.bold ? 'font-weight-bold' : ''">
                      {{ item[header.value] | toCurrency }}
                    </span>
                  </template>
                  <template v-else-if="item.type === 'percentage'">
                    <span
                      :class="
                        item[header.value] > 100 ? 'text-green' : 'text-red'
                      "
                      >{{ item[header.value] }}%</span
                    >
                  </template>
                </template>

                <template v-else>
                  <span class="font-weight-bold">{{ item.name }}</span>
                </template>
              </td>
            </tr>
          </template>
        </Table>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import { mapGetters } from 'vuex'

export default {
  name: 'ReferenceTable',
  components: { Table },
  computed: {
    ...mapGetters([
      'getCompanySalesGoals',
      'getCompanySalesGoalsBusinessYear',
      'getCompanyBusinessYears'
    ])
  },
  created() {
    this.getDataFromApi()
  },
  data() {
    return {
      loading: false,
      selectedTerm: null,
      headers: [{ text: '', align: 'center', value: 'name', sortable: false }],
      items: [
        { id: 'previous_year_target', type: 'amount_goal', name: '目標' },
        {
          id: 'previous_year_results',
          type: 'amount_sale',
          name: '実績',
          bold: true
        },
        {
          id: 'year_on_year_achievement',
          type: 'percentage',
          name: '達成率'
        }
      ]
    }
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      this.$store
        .dispatch('COMPANY_SALES_GOAL_GET', {
          id: this.$route.params.id,
          term: this.selectedTerm?.term || ''
        })
        .then(() => {
          if (this.selectedTerm === null) {
            this.selectedTerm = this.getCompanySalesGoalsBusinessYear.term
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    parseHeaders() {
      let headers = [...this.headers]
      for (const goal of this.getCompanySalesGoals) {
        headers.push({
          text: goal.month.name,
          align: 'center',
          value: goal.month.id,
          sortable: false
        })
      }
      return headers
    },

    parseItems() {
      let items = [...this.items]
      for (const item of items) {
        for (const goal of this.getCompanySalesGoals) {
          if (item.type === 'amount_goal') item[goal.month.id] = goal.goal
          else if (item.type === 'amount_sale') item[goal.month.id] = goal.sale
          else item[goal.month.id] = goal.percentage
        }
      }

      return items
    }
  }
}
</script>

<style lang="scss" src="./ReferenceTable.scss" scoped></style>
