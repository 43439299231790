<template>
  <v-text-field
    outlined
    hide-details
    dense
    :type="isFocused ? 'tel' : 'text'"
    min="0"
    inputmode="numeric"
    :on="$listeners"
    :bind="$attrs"
    v-model="_value"
    @blur="
      isFocused = false
      $emit('input-lose-focus')
    "
    @keydown="filterKey"
    @focus="isFocused = true"
    @keypress="isNumberKey($event)"
    @keydown.48="handleZero"
    @keydown.96="handleZero"
    @keydown.delete="handleDelete($event)"
  >
  </v-text-field>
</template>

<script>
export default {
  name: 'Number',
  props: {
    value: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data() {
    return {
      isFocused: false
    }
  },
  computed: {
    _value: {
      get: function() {
        if (this.isFocused) {
          return this.value.toString()
        } else {
          return this.$options.filters.toCurrency(this.value)
        }
      },
      set: function(modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ''))
        if (isNaN(newValue)) {
          newValue = 0
        }
        this.$emit('input', newValue)
        this.$emit('input-changed')
      }
    }
  },
  methods: {
    filterKey(e) {
      const key = e.key

      if (key === '.') return e.preventDefault()

      if (key === 'e') return e.preventDefault()
    },

    handleDelete(event) {
      if (this.value === 0) event.preventDefault()
    },

    handleZero(event) {
      if (this.value === 0) event.preventDefault()
    },

    isNumberKey: function(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        event.preventDefault()
      }
    }
  }
}
</script>

<style scoped lang="scss" src="./Number.scss"></style>
