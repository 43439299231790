<template>
  <v-row>
    <ReferenceTable />

    <v-col cols="12">
      <v-card>
        <v-card-title>
          <div class="sales-goal-label mr-8">
            {{ $t('page_company_sales_goal_setting_create_new_text') }}
          </div>
          <div class="sales-goal-new-label">{{ getTermLabel() }}</div>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="px-8">
          <v-row>
            <v-col cols="3" class="flex-grow-1">
              <v-select
                class="mode-select"
                append-icon="$customChevronDown"
                :items="modes"
                item-value="value"
                item-text="text"
                v-model="selectedMode"
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              class="flex-grow-0"
              v-if="selectedMode === 'first'"
            >
              <v-container>
                <v-row align="center">
                  <v-col cols="3">
                    <Number
                      class="goal-input"
                      v-model.number="goal"
                      @input="updateMonthlyValue"
                    />
                  </v-col>
                  <v-col cols="auto">
                    <v-btn class="ml-4" small icon @click="resetGoals()">
                      <v-icon>$clear</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>

            <v-col cols="12" v-else>
              <v-container>
                <v-row align="center" class="second-option-label">
                  <v-col cols="auto">
                    {{ $t('value_is') }}
                    <v-btn small icon @click="switchPlus()">
                      <v-icon size="10">
                        {{ isPlus ? '$plus' : '$minus' }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="px-0" cols="1">
                    <div>
                      <v-text-field
                        outlined
                        dense
                        v-model="goalPercentage"
                        hide-details
                        class="percent-input"
                        type="tel"
                        inputmode="numeric"
                        @input="updateMonthlyValue()"
                      >
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    %
                    <v-icon>$rightArrow</v-icon>
                  </v-col>
                  <v-col cols="auto" class="calculated-goal">
                    {{ goal | toCurrency }}
                  </v-col>
                  <v-col cols="auto">
                    <v-btn class="ml-4" small icon @click="resetGoals">
                      <v-icon>$clear</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="px-0">
          <Table
            class="custom-table"
            :headers="headers"
            :items="items"
            :number-of-pages="1"
            :total-records="items.length"
            :footer="false"
            :loading="loading"
          >
            <template v-slot:item="{ item, index, headers }">
              <tr>
                <td
                  class="text-center no-border"
                  v-for="header in headers"
                  :key="`header-${index}-${header.value}`"
                >
                  <template v-if="header.value !== 'name'">
                    <template v-if="item.type === 'editable'">
                      <Number
                        class="monthly-goal-input"
                        v-model.number="item[header.value]"
                        @input="updateTotalValue(header.value)"
                      />
                    </template>

                    <template v-if="item.type === 'amount'">
                      <span :class="item.bold ? 'font-weight-bold' : ''">
                        {{ item[header.value] | toCurrency }}
                      </span>
                    </template>
                    <template v-else-if="item.type === 'percentage'">
                      <span
                        :class="
                          item[header.value] > 100 ? 'text-green' : 'text-red'
                        "
                      >
                        {{ item[header.value] }}%
                      </span>
                    </template>
                  </template>

                  <template v-else>
                    <span class="font-weight-bold">{{ item.name }}</span>
                  </template>
                </td>
              </tr>
            </template>
          </Table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="text-center">
          <v-btn
            type="submit"
            color="#BD6A6A"
            class="text-capitalize font-weight-regular mx-4"
            depressed
            small
            text
            min-width="120"
            exact
            :to="{ name: 'CompanyDetailFLGoal' }"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            class="text-capitalize font-weight-regular mx-4"
            depressed
            small
            min-width="120"
            @click="createGoal"
            :loading="btnLoading"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import ReferenceTable from '@/components/admin/partials/Company/Setting/ReferenceTable'
import { mapGetters } from 'vuex'
import Number from '@/components/admin/partials/Input/Number'

export default {
  name: 'index',
  components: { Number, ReferenceTable, Table },
  computed: {
    ...mapGetters([
      'getCompanyBusinessYears',
      'getCompanySalesGoals',
      'getCompanySalesGoalsBusinessYear'
    ])
  },
  created() {
    this.getDataFromApi()
  },
  data() {
    return {
      loading: false,
      btnLoading: false,
      selectedMode: 'first',
      modes: [
        {
          text: this.$t('page_company_sales_goal_setting_first_option_text'),
          value: 'first'
        },
        {
          text: this.$t('page_company_sales_goal_setting_second_option_text'),
          value: 'second'
        }
      ],
      goal: 0,
      goalPercentage: 0,
      headers: [
        {
          text: '',
          align: 'center',
          value: 'name',
          sortable: false,
          width: 150
        }
      ],
      items: [
        {
          id: 'goal',
          type: 'editable',
          name: this.$t('page_company_sales_goal_setting_item_goal_text')
        },
        {
          id: 'performance',
          type: 'amount',
          name: this.$t('page_company_sales_goal_setting_item_performance_text')
        },
        {
          id: 'achievement_rate',
          type: 'percentage',
          name: this.$t(
            'page_company_sales_goal_setting_item_achievement_rate_text'
          )
        }
      ],
      distributionFactors: [
        0.0813,
        0.065,
        0.0976,
        0.0976,
        0.0813,
        0.0813,
        0.0813,
        0.065,
        0.0813,
        0.0813,
        0.0813,
        0.105
      ],
      isPlus: true
    }
  },
  watch: {
    selectedMode: {
      handler(val) {
        if (val === 'first') this.goal = 0
        this.updateMonthlyValue()
      }
    }
  },
  methods: {
    switchPlus() {
      this.isPlus = !this.isPlus
      if (this.goal !== 0) this.updateMonthlyValue()
    },
    getDataFromApi() {
      this.loading = true
      this.$store
        .dispatch('COMPANY_SALES_GOAL_GET', {
          id: this.$route.params.id
        })
        .then(() => {
          this.parseHeaders()
          this.parseItems()
        })
        .finally(() => {
          this.loading = false
        })
    },

    parseHeaders() {
      let headers = [...this.headers]
      for (const goal of this.getCompanySalesGoals) {
        headers.push({
          text: goal.month.name,
          align: 'center',
          value: goal.month.id,
          sortable: false
        })
      }

      this.headers = headers
    },

    parseItems() {
      let items = [...this.items]
      for (const item of items) {
        for (const goal of this.getCompanySalesGoals) {
          if (item.type === 'editable') item[goal.month.id] = 0
          else if (item.type === 'amount') item[goal.month.id] = goal.sale
          else item[goal.month.id] = 0
        }
      }

      this.items = items
    },

    updateMonthlyValue() {
      if (this.selectedMode !== 'first') {
        let totalSale = this.getCompanySalesGoalsBusinessYear.total_sale
        let calcValue = totalSale * (this.goalPercentage / 100)
        if (this.isPlus) this.goal = totalSale + calcValue
        else this.goal = totalSale - calcValue
      }
      Object.keys(this.items[0]).forEach(index => {
        if (index.match(/^\d/)) {
          let month = parseInt(index.split('-')[1])
          let refValue = this.distributionFactors[month - 1]
          let calcValue = Math.round(this.goal * refValue)
          this.items[0][index] = calcValue
          if (this.items[1][index] !== 0) {
            this.items[2][index] = Math.round(
              (calcValue / this.items[1][index]) * 100
            )
          }
        }
      })
    },

    updateTotalValue(index) {
      let goal = 0

      if (this.items[1][index] !== 0) {
        this.items[2][index] = Math.round(
          (this.items[0][index] / this.items[1][index]) * 100
        )
      }

      Object.keys(this.items[0]).forEach(index => {
        if (index.match(/^\d/)) {
          goal += this.items[0][index]
        }
      })
      this.goal = goal
    },

    createGoal() {
      let goals = []
      Object.keys(this.items[0]).forEach(index => {
        if (index.match(/^\d/)) {
          let goal = {
            month: index,
            goal: this.items[0][index]
          }
          goals.push(goal)
        }
      })
      this.btnLoading = true
      this.$store
        .dispatch('COMPANY_SALES_GOAL_CREATE', {
          id: this.$route.params.id,
          term: this.getCompanyBusinessYears[0].term,
          goals: goals
        })
        .then(() => {
          this.resetGoals()
          this.$router.push({
            name: 'CompanyDetailSalesGoal'
          })
        })
        .finally(() => {
          this.btnLoading = false
        })
    },

    resetGoals() {
      this.goal = 0
      this.goalPercentage = 0
      Object.keys(this.items[0]).forEach(index => {
        if (index.match(/^\d/)) {
          this.items[0][index] = 0
        }
      })
    },

    getTermLabel() {
      let businessYear = this.getCompanyBusinessYears[0]

      return this.$t('year_label', {
        number: businessYear.term,
        start: businessYear.start_date.slice(0, -3),
        end: businessYear.end_date.slice(0, -3)
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
